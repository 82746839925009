import React, { Component } from 'react';

import './index.scss';
import { AppStateContext } from 'src/contexts/app-state-provider.context';
import Layout from 'src/components/layout';
import HowCanI from '../../components/how-can-i';
import { TranslatedBlock } from 'src/components/language';
import Disclaimer from '../../components/disclaimer';
import ExternalLink from '../../components/external-link';
import ConsumerPanel from '../../components/consumer-panel';

class PatientPage extends Component {
  static contextType = AppStateContext;
  componentDidMount() {
    this.context.setSection('patient');
    this.context.setPage('contact-us');
  }

  render() {
    const title = {
      english: 'Contact Us'
    };
    return (
      <Layout title={title} showNavbar={true} className="patient contact-us">
        <section className="page-header">
          <div className="container h-100">
            <div className="row h-100">
              <TranslatedBlock language="english">
                <div className="col-12 col-md-5 p-5 d-flex align-items-center">
                  <h1 className='main-heading'>
                    Contact Us
                  </h1>
                </div>
                <div className="col-12 col-md-7 p-4 p-top60">
                  <h2 className="h1 mb-4">Roche Products (New Zealand) Ltd.</h2>
                  <p className="content-justify">
                    This page is provided as a contact service and the information on this website does not replace the advice of a heathcare professional.
                    For more information on VABYSMO, see the <ExternalLink href="https://www.medsafe.govt.nz/Consumers/CMI/v/vabysmo.pdf">Consumer Medicines
                     Information</ExternalLink> or contact <ExternalLink href="https://MedInfo.roche.com" isRoche="true">Roche Medical Information</ExternalLink>.
                  </p>
                </div>
              </TranslatedBlock>
            </div>
          </div>
        </section>

        <section className="container my-5 px-5">
          <div className="row">
            <div className="col-12">
              <TranslatedBlock language="english">
                <h2 className="h1 ornament pb-3 content-justify">Medical Information and <br/>Adverse Event (Side Effect) Reporting</h2>
              </TranslatedBlock>
              <TranslatedBlock language="english">
                <p className="content-justify">
                  Roche New Zealand is unable to answer questions about medical matters relating to your personal medical condition;
                   these should be discussed with your treating healthcare professional.
                </p>
                <p className="content-justify">
                  If you need to report a side effect about a Roche product, please see the contact information below:
                </p>
              </TranslatedBlock>
            </div>
          </div>
        </section>
        <HowCanI titleEN="How can I contact?" titleFR="">
          <div>
            <p className="content-justify">For questions about Roche medicines or services, to report an adverse event (side effect)
             or product quality defect or to submit a temperature excursion assessment</p>
            <p>
              <b>Visit:</b> <ExternalLink href="https://MedInfo.roche.com" isRoche="true">MedInfo.roche.com</ExternalLink>
            </p>
            <p>
              <b>Phone:</b> <a href="tel:0800 276 243" title="Phone number is:0800 276 243">0800 276 243</a>
            </p>
            <p className="content-justify lh-36">98 Carlton Gore Road <br/>Newmarket <br/>Auckland 1023<br/>
              PO Box 109113 <br/>Newmarket <br/>Auckland 1149</p>
          </div>
        </HowCanI>

        <section className="container my-5 px-5">
          <div className="row">
            <div className="col-12">
          <p className="content-justify">Roche continually monitors and evaluates the safety of our medicines for as long as they are prescribed.
           One way Roche monitors medicine safety is by receiving adverse event or side effect reports about our medicines from patients and medical
            practitioners. An adverse event is any unwanted event associated with the use of a medical product in a patient.</p>
          </div>
          </div>
        </section>
        <Disclaimer />
        <ConsumerPanel />
      </Layout>
    );
  }
}

export default PatientPage;
