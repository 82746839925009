import React from 'react';

import './how-can-i.scss';
import { TranslatedBlock } from './language';

export default function HowCanI({ titleEN, _titleFR, children }) {
  return (
    <section className="how-can-i">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-5 pb-4 pt-5 px-5 d-flex align-items-center">
            <TranslatedBlock language="english">
              <p className="h4 text-white">{titleEN}</p>
            </TranslatedBlock>
          </div>
          <div className="col-12 col-md-5 offset-md-1 pb-4 pt-5 px-5">{children}</div>
        </div>
      </div>
    </section>
  );
}
